import * as XLSX from 'xlsx'

type TableRow = {
  [column: string]: string | number | boolean
}

type TablePayload = {
  [tableName: string]: TableRow[]
}

type ExportTableDataProps = {
  xlsFormat: boolean
  csvFormat: boolean
  tablePayload: TablePayload
  setTableExportLoading?: React.Dispatch<React.SetStateAction<boolean>>
}

export const exportTableData = ({
  xlsFormat,
  csvFormat,
  tablePayload,
  setTableExportLoading,
}: ExportTableDataProps) => {
  const sheets = Object.keys(tablePayload)
  if (sheets.length < 1) {
    return
  }
  const fileName = sheets.length === 1 ? sheets[0] : new Date().toLocaleDateString('en-GB').replace(/\//g, '-')

  if (csvFormat) {
    const csvData = formatDataToCSV(tablePayload, sheets)
    downloadCSV(csvData, `${fileName}.csv`)
  }

  if (xlsFormat) {
    const workbook = XLSX.utils.book_new()

    sheets.forEach((tableName) => {
      const tableData = tablePayload[tableName]
      const tableHeader = Array.from(new Set(tableData.flatMap(Object.keys)))

      const worksheetData = [
        tableHeader,
        ...tableData.map((row) =>
          tableHeader.map((col) => {
            const value = row[col] // returnovao bih samo row[col] ali moze se desiti da je niz
            if (Array.isArray(value)) {
              return value.length === 1 ? value[0] : JSON.stringify(value)
            }
            return value
          }),
        ),
      ]
      console.log('worksheetData', worksheetData)
      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData)

      XLSX.utils.book_append_sheet(workbook, worksheet, tableName)
    })

    XLSX.writeFile(workbook, `${fileName}.xlsx`)
  }
  setTableExportLoading && setTableExportLoading(false)
}

const formatDataToCSV = (tablePayload: TablePayload, tableNames: string[]) => {
  if (tableNames.length === 1) {
    return arrayToCSV(tablePayload[tableNames[0]])
  }

  return tableNames
    .map((tableName) => {
      const tableData = tablePayload[tableName] as TableRow[]
      const tableCSV = arrayToCSV(tableData)
      return `${tableName}\n${tableCSV}`
    })
    .join('\n\n')
}

function arrayToCSV(data: TableRow[]) {
  if (!data || !data.length) return ''

  const headers = Array.from(new Set(data.flatMap(Object.keys)))
  const rows = data.map((obj) => headers.map((header) => JSON.stringify(obj[header] || '')).join(','))

  return [headers.join(','), ...rows].join('\n')
}

function downloadCSV(csv: string, filename: string) {
  const blob = new Blob([csv], { type: 'text/csv' })
  const url = URL.createObjectURL(blob)

  const link = document.createElement('a')
  link.href = url
  link.download = filename

  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)

  URL.revokeObjectURL(url)
}

export default exportTableData
