import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import ServiceAccountsService from '../../../services/ServiceAccounts.service'

export const useAddServiceAcc = () => {
  const queryClient = useQueryClient()
  const { org } = useParams()

  return useMutation<any, Error, { body: { name: string; nodeId: string; credentialType: string } }>({
    mutationKey: ['add-service-acc'],
    mutationFn: (payload) => ServiceAccountsService.addServiceAcc(org!, payload.body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['service-accounts'] })
    },
  })
}
