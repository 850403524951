import { Dialog } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/solid'
import { Button, Input, ModalDialog, Select, SpinnerIcon, Option } from '@sistemiv/s-components'
import { DispatchSetAction } from '@sistemiv/s-components/dist/esm/types/types'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useOrgNodes } from '../../repositories/organization-nodes/org-nodes.repository'
import { OrgNode } from '../../models/OrganizationalNode'
import OrgPositionCombobox from './OrgPositionCombobox'

export const credentialTypes = [
  { id: 'ApiKey', name: 'ApiKey' },
  { id: 'DataWarehouse', name: 'DataWarehouse' },
]

const AddServiceAccountModal: FC<{
  open: boolean
  setOpen: DispatchSetAction<boolean>
  onAdd?(name: string, nodeId: string, credentialType: string): void
  isAdding?: boolean
}> = ({ open, setOpen, onAdd, isAdding }) => {
  const [name, setName] = useState('')
  const [credentialType, setCredentialType] = useState(credentialTypes[0].name)
  const { t } = useTranslation()
  const { data: orgNodes, isLoading } = useOrgNodes()
  const [selectedNode, setSelectedNode] = useState<OrgNode>()

  return (
    <ModalDialog isOpen={open} setOpen={setOpen} className='!max-w-sm'>
      <Dialog.Title as='h3' className='leading-6 text-lg flex items-center justify-between'>
        {t('ServiceAccounts.addServiceAccount')}
        <button onClick={() => setOpen(false)} className='rounded-full p-2 hover:bg-gray-100 active:text-blue-500'>
          <XMarkIcon className='w-5 h-5 stroke-gray-500 active:stroke-blue-500' />
        </button>
      </Dialog.Title>
      {isLoading ? (
        <div className='w-full flex items-center justify-center py-6'>
          <SpinnerIcon className='w-6 h-6 text-sky-500' />
        </div>
      ) : (
        <div className='pt-5 space-y-6'>
          <div className='mt-3'>
            <p className='text-s pb-2 text-gray-600'>{t('ServiceAccounts.name')}</p>
            <Input
              id='name'
              type='text'
              removePencil
              classes='!rounded-md !border-solid border border-gray-300 focus:border-sky-100'
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className='mt-3'>
            <p className='text-s pb-2 text-gray-600'>{t('ServiceAccounts.orgPosition')}</p>
            <OrgPositionCombobox
              selected={selectedNode!}
              onChange={setSelectedNode}
              options={orgNodes?.results.filter((node) => node.id !== selectedNode?.id)}
              disabled={orgNodes?.results.length === 0 || (!!selectedNode && orgNodes?.results?.length === 1)}
            />
          </div>
          <div className='mt-3'>
            <p className='text-s pb-2 text-gray-600'>{t('ServiceAccounts.credentialType')}</p>
            <Select value={credentialType} onChange={setCredentialType}>
              {credentialTypes.map((type) => (
                <Option key={type.id} value={type.name}>
                  <p>{type.name}</p>
                </Option>
              ))}
            </Select>
          </div>
          <div className='flex items-center justify-end gap-x-3'>
            <Button onClick={() => setOpen(false)} className='hover:bg-gray-100 active:text-blue-500'>
              {t('ServiceAccounts.cancel')}
            </Button>
            <Button
              onClick={() => onAdd && selectedNode && onAdd(name, selectedNode.id, credentialType)}
              disabled={name === '' || !selectedNode || isLoading}
              className='text-white bg-blue-500 disabled:bg-slate-200 disabled:text-slate-400'
              loading={isAdding}
            >
              {t('ServiceAccounts.create')}
            </Button>
          </div>
        </div>
      )}
    </ModalDialog>
  )
}

export default AddServiceAccountModal
