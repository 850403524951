import React, { FC, useCallback, useEffect, useState } from 'react'
import Table, { TableHeaders, TableRow } from '../table/Table'
import TableHeader from '../table-header/TableHeader'
import { InviteUserRequest, OverviewUser, OverviewUserResponse, Status } from '../../models/User'
import { useParams } from 'react-router-dom'
import UserService from '../../services/UserService'
import { useApplications } from '../../context/ApplicationsContext'
import InviteUsersModal from '../invite-user-modal/InviteUserModal'
import { ExportTableDataModal, SpinnerIcon } from '@sistemiv/s-components'
import { imageBase } from '../../services/http-common'
import { useTranslation } from 'react-i18next'
import { useInviteUser } from '../../repositories/users/mutations/invite-user.mutation'
import { refreshUsersEvents, SignalRContext } from '../../pages/Dashboard'
import exportTableData from '../../utils/exportTableData'

const UsersPage: FC = () => {
  const { org } = useParams()
  const [users, setUsers] = useState<OverviewUserResponse | null>(null)
  const [tableFilters, setTableFilters] = useState<{ [key: string]: any }>({
    groups: [],
    orgPositions: [],
    apps: [],
  })
  const [rows, setRows] = useState<TableRow[]>([])
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(20)
  const [totalUserSize, setTotalUserSize] = useState<number>(0)
  const [statuses, setStatuses] = useState<Status[]>([])
  const { applications } = useApplications()
  const [inviteModalOpen, setInviteModalOpen] = useState(false)
  const { t } = useTranslation()
  const { mutate: inviteUserMutation, isPending: invitingUser } = useInviteUser()
  //TODO: switch to useQuery like it is done on GroupsPage.tsx
  const [loading1, setLoading1] = useState(false)
  const [loading4, setLoading4] = useState(false)
  const [exportModalOpen, setExportModalOpen] = useState(false)
  const [tableExportLoading, setTableExportLoading] = useState(false)

  const firstHeaders = [
    {
      accessor: 'name',
      label: t('Users.name'),
      searchType: 'search',
    },
    {
      accessor: 'email',
      label: t('Users.email'),
      searchType: 'search',
    },
  ]
  const lastHeaders = [
    {
      accessor: 'status',
      label: t('Users.status'),
      searchType: 'select',
      options: statuses,
    },
    {
      accessor: 'action',
      label: t('Users.actions'),
      searchType: '',
    },
  ]
  const [headers, setHeaders] = useState<TableHeaders[]>([...firstHeaders, ...lastHeaders])
  const colorForStatus = (status: string) => {
    switch (status) {
      case 'Pending approval':
        return '#E2266A'
      case 'Invitation expired':
        return '#E2266A'
      case 'Invitation sent':
        return '#00A0A6'
      case 'Inactive':
        return '#26282F'
      default:
        return '#26282F'
    }
  }
  useEffect(() => {
    const newHeaders: TableHeaders[] = []
    applications?.forEach((app) => {
      if (!newHeaders.find((h) => h.accessor === app.code)) {
        newHeaders.push({
          accessor: app.code,
          label: app.code,
          searchType: '',
        })
      }
    })
    const newRows = users?.users?.map((user) => {
      const retval: TableRow = {
        id: {
          type: 'name',
          value: user.id,
        },
        name: {
          type: 'name',
          value: user.name,
          image: `${imageBase}/${user.objectId}/76`,
        },
        email: {
          type: 'plainText',
          value: user.email,
        },
        status: {
          type: 'coloredText',
          value: user.status.status,
          color: colorForStatus(user.status.status),
        },
        objectId: {
          type: 'name',
          value: user.objectId,
        },
        appRoles: {
          type: 'app_roles',
          value: user.apps,
        },
      }

      for (const appHeader of newHeaders) {
        retval[appHeader.accessor] = {
          type: 'checkbox',
          value: appHeader.accessor,
          checked: user.apps.find((app) => app.id === appHeader.accessor) ? true : false,
        }
      }

      return retval
    })

    setHeaders([...firstHeaders, ...newHeaders, ...lastHeaders])
    setRows(newRows ?? [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users, applications])

  const fetchData = useCallback(() => {
    if (!org) return
    const groupIds = tableFilters['groups']?.map((f: any) => f.id)
    const organizationNodeIds = tableFilters['orgPositions']?.map((f: any) => f.id)
    const applicationCodes = tableFilters['apps']?.map((f: any) => f.id)
    const statusIds =
      tableFilters['status'] && tableFilters['status'].length > 0
        ? tableFilters['status']?.map((f: any) => f.id)
        : statuses?.map((f: any) => f.id)
    const email = tableFilters['email']?.value
    const name = tableFilters['name']?.value

    setLoading1(true)
    UserService.listOrganizationUsers(
      org,
      page,
      size,
      groupIds,
      organizationNodeIds,
      applicationCodes,
      statusIds,
      name,
      email,
    ).then((value) => {
      setTotalUserSize(value?.total)
      setUsers(value)
      setLoading1(false)
    })
  }, [org, page, size, tableFilters, statuses])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  SignalRContext.useSignalREffect(
    'sync',
    //@ts-ignore
    (payload) => {
      if (refreshUsersEvents.includes(payload)) {
        fetchData()
      }
    },
    [],
  )

  useEffect(() => {
    if (!org) return
    setLoading4(true)
    UserService.listStatuses(org).then((response) => {
      setStatuses(response?.statuses ?? [])
      setLoading4(false)
    })
  }, [org])

  const handleInviteUser = (body: InviteUserRequest) => {
    if (!org) return
    inviteUserMutation(
      {
        organization: org,
        body: body,
      },
      {
        onSuccess: () => {
          fetchData()
          setInviteModalOpen(false)
        },
      },
    )
  }

  const handleExport = async (exportOptions: any) => {
    if (loading1 || loading4 || !rows) return

    if (!org) return
    const groupIds = tableFilters['groups']?.map((f: any) => f.id)
    const organizationNodeIds = tableFilters['orgPositions']?.map((f: any) => f.id)
    const applicationCodes = tableFilters['apps']?.map((f: any) => f.id)
    const statusIds =
      tableFilters['status'] && tableFilters['status'].length > 0
        ? tableFilters['status']?.map((f: any) => f.id)
        : statuses?.map((f: any) => f.id)
    const email = tableFilters['email']?.value
    const name = tableFilters['name']?.value

    let users: OverviewUser[]

    setTableExportLoading(true)

    UserService.listOrganizationUsers(
      org,
      page,
      totalUserSize,
      groupIds,
      organizationNodeIds,
      applicationCodes,
      statusIds,
      name,
      email,
    )
      .then((value) => {
        users = value.users

        exportTableData({
          xlsFormat: exportOptions.exportExcel,
          csvFormat: exportOptions.exportCsv,
          tablePayload: { Users: transformTableRows(users) },
          setTableExportLoading,
        })
      })
      .catch(() => {
        setTableExportLoading(false)
        console.error('error exporting users')
      })
  }
  const transformTableRows = (users: OverviewUser[]): { [key: string]: string }[] => {
    return users.map((user) => {
      const transformedRow: { [key: string]: any } = {}

      transformedRow['status'] = user.status.status

      user.apps.forEach((app) => {
        transformedRow[app.id] = 'role:' + app.roleCode
      })

      for (const key in user) {
        if (key == 'status' || key == 'apps' || key == 'photo' || key == 'objectId') continue
        transformedRow[key] = user[key]
      }

      return transformedRow
    })
  }

  return (
    <div className='overflow-hidden flex flex-col h-full w-full'>
      <TableHeader
        title={t('users')}
        total={users ? users.total : 1}
        page={page + 1}
        size={size}
        onFilterChanged={(field, val) =>
          setTableFilters((old) => {
            return {
              ...old,
              [field]: val ?? [],
            }
          })
        }
        groupSelected={tableFilters['groups']}
        productSelected={tableFilters['apps']}
        positionSelected={tableFilters['orgPositions']}
        onPageChange={(page) => setPage(page - 1)}
        onSizeChange={(size) => setSize(size)}
        onInviteClicked={() => setInviteModalOpen(true)}
        onExportClicked={() => setExportModalOpen(true)}
        tableExportLoading={tableExportLoading}
      />
      <div className={`px-8 pt-3 py-1 grow overflow-hidden ${loading1 || loading4 ? 'opacity-50' : 'opacity-100'}`}>
        <div className='w-full h-full overflow-auto relative'>
          {(loading1 || loading4) && (
            <div className='absolute first:w-full flex justify-center items-center pt-5'>
              <SpinnerIcon className='text-sky-500 h-7 w-7'></SpinnerIcon>
            </div>
          )}
          <Table
            headers={headers ?? []}
            rows={rows ?? []}
            onHeaderFilter={(field, value) =>
              setTableFilters((old) => {
                return {
                  ...old,
                  [field]: value ?? undefined,
                }
              })
            }
            refreshTable={fetchData}
            filterValues={tableFilters}
          />
        </div>
      </div>
      {inviteModalOpen && (
        <InviteUsersModal
          open={inviteModalOpen}
          setOpen={setInviteModalOpen}
          onAdd={handleInviteUser}
          inviting={invitingUser}
        />
      )}
      {exportModalOpen && (
        <ExportTableDataModal
          open={exportModalOpen}
          setOpen={setExportModalOpen}
          onConfirm={(val) => {
            handleExport(val)
            setExportModalOpen(false)
          }}
          selectableRows={false}
          selectableHistory={false}
        />
      )}
    </div>
  )
}

export default UsersPage
