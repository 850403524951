import React, { FC, useEffect, useMemo, useState } from 'react'
import DomainChips from './DomainChips'
import RadioItem from './RadioItem'
import { useCurrentOrganization } from '../../../repositories/organizations/organization.respository'
import { Input, SpinnerIcon } from '@sistemiv/s-components'
import { useUpdateOrganization } from '../../../repositories/organizations/mutations/organization-patch.mutation'
import { useParams } from 'react-router'
import { useTranslation } from 'react-i18next'

const GeneralTab: FC = () => {
  const { t } = useTranslation()
  const { org } = useParams()
  const options = useMemo(() => ['Private', 'PartiallyPublic', 'Public'], [])
  const [allowedDomains, setAllowedDomains] = useState<string[]>([])
  const { data, isLoading, isFetching } = useCurrentOrganization()
  const { mutate, isPending } = useUpdateOrganization()

  const handleUpdatePrivacy = (option: string) => {
    if (!org) return
    mutate({ organization: org, field: 'privacy-type', body: { privacyType: option, domains: allowedDomains } })
  }

  const updateDataCollection = (value) => {
    if (!org) return
    mutate({ organization: org, field: 'data-collection', body: { collectData: value } })
  }

  const handleAddDomain = (value: string[]) => {
    if (!org) return
    mutate({ organization: org, field: 'privacy-type', body: { privacyType: options[1], domains: value } })
    setAllowedDomains(value)
  }

  useEffect(() => {
    if (!isFetching) {
      setAllowedDomains(Array.from(new Set(data?.privacy.allowedDomains)))
    }
  }, [data, options, isFetching])

  return (
    <div className='relative'>
      <p className='text-gray-400 pt-6 pb-6'>{t('Settings.allowToJoin')}</p>
      {(isLoading || isFetching || isPending) && (
        <div className='absolute w-1/2 flex items center justify-center'>
          <SpinnerIcon className='w-7 h-7 text-sky-500' />
        </div>
      )}
      <div className={`flex flex-col gap-y-4 ${isLoading || isFetching || isPending ? 'opacity-50' : 'opacity-100'}`}>
        <div className='flex items-center gap-x-3'>
          <RadioItem value={data?.privacy.type ?? ''} option={options[0]} onChange={handleUpdatePrivacy} />
          <div>{t('Settings.private')}</div>
        </div>
        <div className='flex flex-col gap-y-2'>
          <div className='flex items-center gap-x-3'>
            <RadioItem value={data?.privacy.type ?? ''} option={options[1]} onChange={handleUpdatePrivacy} />
            <div className='flex items-center justify-center gap-x-3'>
              {t('Settings.partiallyPublic')}
              <DomainChips
                value={allowedDomains}
                onChange={handleAddDomain}
                readonly={data?.privacy.type !== options[1]}
                placeholder={t('Settings.addAllowedDomains') as string}
              />
            </div>
          </div>
          {options[1] === data?.privacy.type && (
            <div className='pl-8'>
              <Input
                id='checkbox-allowed-public'
                value={true}
                checked={true}
                disabled={true}
                type='checkbox'
                label={t('Settings.requireApproval') as string}
              />
            </div>
          )}
        </div>
        <div className='flex flex-col gap-y-2'>
          <div className='flex items-center gap-x-3'>
            <RadioItem value={data?.privacy.type ?? ''} option={options[2]} onChange={handleUpdatePrivacy} />
            <div>{t('Settings.public')}</div>
          </div>
          {options[2] === data?.privacy.type && (
            <div className='pl-8'>
              <Input
                id='checkbox-allowed-public'
                value={true}
                checked={true}
                disabled={true}
                type='checkbox'
                label={t('Settings.requireApproval') as string}
              />
            </div>
          )}
        </div>
      </div>
      <div>
        <p className='text-gray-400 pt-8 pb-6'>{t('Settings.dataCollection')}</p>
        <Input
          id='checkbox-allowed-public'
          mainClasses='pl-1'
          value={data?.privacy.dataCollectionEnabled}
          checked={data?.privacy.dataCollectionEnabled}
          disabled={false}
          onChange={() => updateDataCollection(!data?.privacy.dataCollectionEnabled)}
          type='checkbox'
          label={t('Settings.enableDataWarehousing') as string}
        />
      </div>
    </div>
  )
}

export default GeneralTab
