import { Combobox, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import React, { FC, Fragment, useEffect, useState } from 'react'
import DataField from './DataField'
import { TableField } from '../table/Table'
import { useTranslation } from 'react-i18next'
import UserService from '../../services/UserService'
import { useParams } from 'react-router-dom'
import { FieldValueResponse } from '../../models/Field'

type TableHeaderSearchCoboboxProps = {
  onSearch?: (value?: TableField) => void
  searchValue?: TableField
  placeholder?: string
  field: string
}
const TableHeaderSearchCobobox: FC<TableHeaderSearchCoboboxProps> = ({ searchValue, onSearch, placeholder, field }) => {
  const [query, setQuery] = useState('')
  const { t } = useTranslation()
  const { org } = useParams()
  const [searchOptions, setSearchOptions] = useState<TableField[]>([])
  useEffect(() => {
    const delayTyping = setTimeout(() => {
      if (!org) return
      UserService.listFieldValues(
        org,
        field,
        0,
        20,
        query,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
      ).then((value: FieldValueResponse) => {
        setSearchOptions(
          (value?.values?.map((v) => v.name) || [])?.map((h) => {
            return {
              type: 'plainText',
              value: h,
            }
          }) ?? [],
        )
      })
    }, 500)

    return () => clearTimeout(delayTyping)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, org])

  const filteredOptions =
    query === ''
      ? searchOptions
      : searchOptions.filter((option) =>
          option.value.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, '')),
        )
  return (
    <Combobox value={searchValue} onChange={onSearch}>
      <div className='w-full relative'>
        <div className='relative border border-gray-300 cursor-default overflow-hidden bg-white text-left rounded-md focus-within:border-transparent focus-within:ring-2 focus-within:ring-sky-100'>
          <Combobox.Input
            key={searchValue?.value}
            displayValue={() => {
              return searchValue ? searchValue.value : ''
            }}
            onChange={(e) => setQuery(e.target.value)}
            className='peer w-full text-sm relative border-none focus:ring-0 focus:ring-offset-0 active:outline-sky-100 pl-3 py-2 pr-10 font-normal text-slate-500'
            placeholder={placeholder ?? t('Users.search')!}
          />
          {searchValue && (
            <div className='absolute inset-y-0 inline-flex items-center text-center right-0 pr-3'>
              <button>
                <XMarkIcon className='w-4 h-4 text-gray-600' onClick={() => onSearch?.(undefined)} />
              </button>
            </div>
          )}
        </div>
        <Transition
          as={Fragment}
          leave='transition ease-in duration-100'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
          afterLeave={() => setQuery('')}
        >
          <Combobox.Options className='absolute mt-1 max-h-60 w-fit max-w-sm overflow-x-hidden overflow-y-auto bg-white py-1 text-sm drop-shadow-lg w-sm rounded-md z-30'>
            <div className='mt-2'>
              {filteredOptions.length === 0 && query !== '' ? (
                <div className='relative cursor-default select-none py-2 px-4 text-gray-700'>
                  {t('Users.nothingFound')}
                </div>
              ) : (
                filteredOptions?.map((o, index) => (
                  <Combobox.Option
                    key={`${o.id} ${index}`}
                    value={o}
                    className={`relative cursor-pointer select-none py-2 pl-2 pr-4 text-slate-600 hover:bg-sky-50 active:bg-sky-100`}
                  >
                    {({ selected }) => (
                      <div className={`${selected ? 'opacity-100' : 'opacity-70'} whitespace-nowrap`}>
                        <DataField field={o} />
                      </div>
                    )}
                  </Combobox.Option>
                ))
              )}
            </div>
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  )
}

export default TableHeaderSearchCobobox
