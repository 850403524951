import { PlusIcon } from '@heroicons/react/24/solid'
import { Button, PaginationController } from '@sistemiv/s-components'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface GroupsHeaderProps {
  total: number
  page: number
  size: number
  onPageChange?(page: number): void
  onSizeChange?(size: number): void
  onCreateGroup?(): void
}

const GroupsHeader: FC<GroupsHeaderProps> = ({ total, page, size, onPageChange, onSizeChange, onCreateGroup }) => {
  const { t } = useTranslation()
  return (
    <div className='flex flex-none items-center justify-between border-b border-gray-300 px-8 py-3'>
      <div className='flex items-center gap-x-16'>
        <p className='text-2xl'>{t('groups')}</p>
        <div className='flex items-center gap-x-4'>
          <Button
            className='text-blue-500 flex items-center gap-x-2 border border-blue-500'
            onClick={() => onCreateGroup && onCreateGroup()}
          >
            <PlusIcon className='w-4 h-4 flex-none' />
            <>{t('Groups.group')}</>
          </Button>
        </div>
      </div>
      <div className='flex items-center gap-x-4'>
        {total > 0 && (
          <PaginationController
            total={total}
            size={size}
            page={page}
            onPageChange={onPageChange}
            onSizeChange={onSizeChange}
          />
        )}
      </div>
    </div>
  )
}

export default GroupsHeader
