import http from './http-common'

class ServiceAccountsService {
  async listServiceAccounts(
    organization: string,
    page: number,
    size: number,
    name?: string,
    organizationalNodeId?: string[],
    credentialTypes?: string[],
  ) {
    return (
      await http.get(`${organization}/service-accounts`, {
        params: {
          page: page,
          size: size,
          name: name,
          organizationalNodeIds: organizationalNodeId,
          credentialTypes: credentialTypes,
        },
        paramsSerializer: { indexes: null },
      })
    ).data
  }

  addServiceAcc = async (
    organization: string,
    body: {
      name: string
      nodeId: string
      credentialType: string
    },
  ): Promise<any> => {
    return (await http.post(`/${organization}/service-accounts`, body)).data
  }

  async deleteServiceAcc(organization: string, id: string) {
    return (await http.delete(`/${organization}/service-accounts/${id}`)).data
  }

  async updateName(id: string, organization: string, name: string) {
    return (await http.post(`/${organization}/service-accounts/${id}/name`, { name: name })).data
  }

  async updateOrgPosition(id: string, organization: string, node: string) {
    return (await http.post(`/${organization}/service-accounts/${id}/node`, { nodeId: node })).data
  }

  async getCredential(organization: string, id: string) {
    return (await http.get(`${organization}/service-accounts/${id}/credentials`)).data
  }
}

export default new ServiceAccountsService()
