import React, { FC, useEffect, useMemo, useState } from 'react'
import { Button, PaginationController } from '@sistemiv/s-components'
import TableHeaderCombobox from './TableHeaderCombobox'
import { TableField } from '../table/Table'
import { GroupResponse } from '../../models/Group'
import { useParams } from 'react-router-dom'
import UserService from '../../services/UserService'
import { OrgNodeResponse } from '../../models/OrganizationalNode'
import { useApplications } from '../../context/ApplicationsContext'
import { useTranslation } from 'react-i18next'

type ProcessInstanceHeaderProps = {
  title: string
  total: number
  page: number
  size: number
  groupSelected: TableField[]
  productSelected: TableField[]
  positionSelected: TableField[]
  onFilterChanged: (field: string, values: any) => void
  onPageChange?: (page: number) => void
  onSizeChange?: (size: number) => void
  onInviteClicked?(): void
  onExportClicked?(): void
  tableExportLoading?: boolean
}

const ProcessInstanceHeader: FC<ProcessInstanceHeaderProps> = ({
  title,
  total,
  page,
  size,
  onPageChange,
  groupSelected,
  productSelected,
  positionSelected,
  onFilterChanged,
  onSizeChange,
  onInviteClicked,
  onExportClicked,
  tableExportLoading,
}) => {
  const [groupOptions, setGroupOptions] = useState<GroupResponse | null>(null)
  const [orgNodesOptions, setOrgNodesOptions] = useState<OrgNodeResponse | null>(null)
  const { applications } = useApplications()
  const { t } = useTranslation()
  const appOptions = useMemo<TableField[]>(
    () =>
      applications?.map((app) => {
        return { type: 'plainText', value: app.name, id: app.code }
      }),
    [applications],
  )
  const { org } = useParams()
  useEffect(() => {
    if (!org) return
    UserService.listGroups(org).then((response) => {
      setGroupOptions(response)
    })
    UserService.listOrgNodes(org).then((response) => {
      setOrgNodesOptions(response)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [org])

  return (
    <div className='flex flex-none items-center justify-between border-b border-gray-300 px-8 py-3'>
      <div className='flex items-center gap-x-16'>
        <p className='text-2xl'>{title}</p>
        <div className='flex items-center gap-x-4'>
          <Button className='border text-white bg-blue-500 inline-flex gap-x-2 items-center' onClick={onInviteClicked}>
            {t('Users.inviteUsers')}
          </Button>
          <Button
            className='border border-blue-500 text-blue-500 inline-flex gap-x-2 items-center'
            onClick={onExportClicked}
            loading={tableExportLoading}
          >
            {' '}
            {t('Users.export')}
          </Button>
        </div>
      </div>
      <div className='flex items-center gap-x-4'>
        <TableHeaderCombobox
          options={
            groupOptions?.results?.map((o) => {
              return {
                type: 'circle_label',
                value: o.name,
                base64image: o.icon,
                id: o.id,
              }
            }) ?? []
          }
          value={groupSelected}
          selectFor={t('Users.groups')}
          onChange={(val) => onFilterChanged('groups', val ?? [])}
        />
        <TableHeaderCombobox
          options={
            orgNodesOptions?.results?.map((o) => {
              return {
                type: 'plainText',
                value: o.name,
                id: o.id,
              }
            }) ?? []
          }
          value={positionSelected}
          selectFor={t('Users.orgPosition')}
          onChange={(val) => onFilterChanged('orgPositions', val ?? [])}
        />
        <TableHeaderCombobox
          options={appOptions}
          value={productSelected}
          selectFor={t('Users.products')}
          onChange={(val) => onFilterChanged('apps', val ?? [])}
        />
        {total > 20 && (
          <PaginationController
            total={total}
            size={size}
            page={page}
            onPageChange={onPageChange}
            onSizeChange={onSizeChange}
          />
        )}
      </div>
    </div>
  )
}

export default ProcessInstanceHeader
